<template>
    <div>
      <div v-if="photoDataUrl !== null">
        <img :src="photoDataUrl" alt="사진">
      </div>  
      <div class="container">
        <div class="row">
          <div class="col-sm">
            <button 
            type="button" 
            class="btn btn-success btn-lg btn-block" 
            style="margin-bottom: 10px;" 
            @click="takePhoto">촬영</button>
          </div>
          <div class="col-sm">
            <button 
            type="button" 
            class="btn btn-success btn-lg btn-block" 
            style="margin-bottom: 10px;" 
            @click="savePhoto()">저장</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import manageService from "../services/manageService"; 
  export default {
    data() {
      return {
        photoDataUrl: null
      };
    },
    methods: {
      async takePhoto() {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
          const video = document.createElement('video');
          video.srcObject = stream;
          video.play();
  
          setTimeout(() => {
            // Canvas 생성
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext('2d');
            
            // 비디오의 현재 프레임을 캔버스에 그리기
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
            // 캔버스 데이터를 Blob으로 변환
            canvas.toBlob(async blob => {
              // Blob을 FormData에 추가
              const formData = new FormData();
              formData.append('image', blob);
  
              // 이미지를 서버에 전송
              //const response = await axios.post('abc.php', formData);
  
            await manageService
            .savephoto(formData)
            .then((response) => {

          
                console.log('사진이 서버에 저장되었습니다.', response.data);                
                //this.manageLists = response.data;
                //this.gradegrouplist = response.data;                     
            })
            .catch((e) => {
                console.log(e);
            });

              //console.log('사진이 서버에 저장되었습니다.', response.data);
  
              // 사용이 끝난 스트림 및 비디오를 해제
              stream.getTracks().forEach(track => track.stop());
              video.srcObject = null;
            }, 'image/jpeg');
  
            // 캔버스 데이터를 이미지 URL로 변환하여 화면에 표시
            this.photoDataUrl = canvas.toDataURL('image/jpeg');
          }, 1000);
        } catch (error) {
          console.error('사진을 찍는 도중 오류가 발생했습니다.', error);
        }
      },
      async savePhoto() {
        try {
          // 이미지 데이터를 서버에 전송

        //   const ndata = {
        //     image: this.photoDataUrl
        //   };
        //     await manageService
        //     .savephoto(ndata)
        //     .then((response) => {

          
        //         console.log('사진이 서버에 저장되었습니다.', response.data);                
        //         //this.manageLists = response.data;
        //         //this.gradegrouplist = response.data;                     
        //     })
        //     .catch((e) => {
        //         console.log(e);
        //     });

          
          
          // 이미지 저장 후에는 이미지 데이터 초기화
          this.photoDataUrl = null;
        } catch (error) {
          console.error('사진을 서버에 저장하는 도중 오류가 발생했습니다.', error);
        }
      }
    }
  }
  </script>
  
  <style scoped>
  img {
    max-width: 100%;
    height: auto;
  }
  </style>
  